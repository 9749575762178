export function isNotNullOrEmpty<T>(value: T | null | undefined): value is T {
  if (Array.isArray(value)) {
    return value !== null && value !== undefined && value.length !== 0;
  }
  return value !== undefined && value !== null && value !== '';
}

export function isNullOrEmpty<T>(value: T | null | undefined): value is T {
  if (Array.isArray(value)) {
    return value === null || value === undefined || value.length === 0;
  }
  return value === null || value === undefined || value === '' || (typeof value === 'number' && Number.isNaN(value));
}

export function isNullOrDefault<T>(value: T | null | undefined, validationValue: T | null | undefined): value is T {
  return value === null || value === undefined || value === '' || value === validationValue;
}
